<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-col class="container">
      <v-row class="header ma-6">
        <span class="config-title">모드선택</span>
      </v-row>
      <v-row
        class="setting-area"
      >
        <v-col>
          <img
            :src="require('@/assets/image/mode/mode_wheelchair.png')"
            alt="자율주행휠체어"
            type="button"
            @click="$router.replace('/')"
          >
          <div>자율주행휠체어</div>
        </v-col>
        <v-col>
          <img
            :src="require('@/assets/image/mode/mode_gew.png')"
            alt="범용전동휠체어"
            type="button"
            @click="$router.replace('/simple')"
          >
          <div>범용전동휠체어</div>
        </v-col>
        <v-col>
          <img
            :src="require('@/assets/image/mode/mode_ges.png')"
            alt="범용전동스쿠터"
            type="button"
            @click="$router.replace('/simple')"
          >
          <div>범용전동스쿠터</div>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'Mode',
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.container > .header {
  top: 0;
}

.container > .header > .config-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
  width: 100%;
  text-align: center;
}

.setting-area {
  color: #ffffff;
  width: 100%;
  text-align: center;
}

@media (max-width: 1485px) {
  img {
    width: 200px;
    height: 200px;
  }

  .container > .header > .config-title {
    font-size: 40px !important;
  }
}

@media (max-width: 1024px) {
  img {
    width: 100px;
    height: 100px;
  }

  .container > .header > .config-title {
    font-size: 20px !important;
  }

}
</style>
